import React from 'react';

function App() {
  return (
    <div className="inset-0 bg-gray-800 h-screen w-screen flex flex-col justify-center items-center">
      <div className="p-8 font-serif text-amber-200 text-8xl">WWP</div>
      <div className="p-8 font-serif text-amber-200 text-2xl">
        coming soon ...
      </div>
    </div>
  );
}

export default App;
